import Button, { ButtonType } from '../../helpers/customComponents/Button'
import { ButtonTheme } from '../../helpers/constants/enum'
import { bookmarks, getBookmarks } from '../../extensionCommunication/messager'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from '../../apiClient'

export default function SyncConfirmationScreen(props: {
    folderName: string
    localFolderID: string
    user: User | null
}) {
    const [bookmarks, setBookmarks] = useState<null | bookmarks[]>(null)
    const navigate = useNavigate()

    useEffect(() => {
        getBookmarks().then((b) => {
            setBookmarks(b)
        })
    }, [])

    return (
        <div className="pb-4 flex justify-center items-center lg:pb-2 min-h-[95%]">
            <div className=" lg:flex lg:justify-between lg:shadow rounded min-h-full mt-4 lg:mt-0">
                <section className="flex flex-col lg:pb-32 lg:w-1/2 lg:h-full lg:block">
                    <h2 className="text-xl font-bold text-center mt-8 pb-14 lg:mt-20  lg:mx-8">
                        {props.folderName
                            ? `"${props.folderName}"`
                            : 'Your folder'}
                        &nbsp;has been successfully synchronized!
                    </h2>
                    <footer className="text-xl">
                        <h2 className="font-semibold text-center mb-6">
                            Would you like to share this folder{' '}
                            <br className="hidden sm:inline" /> with another
                            person?
                        </h2>
                        <Button
                            buttonText="Share my folder"
                            buttonType={ButtonType.button}
                            disabled={false}
                            buttonTheme={ButtonTheme.primary}
                            className="w-full sm:max-w-max sm:px-20 text-xl mx-auto mb-16"
                            onClick={() => {
                                const sharedFolder = bookmarks?.find(
                                    (b) => b.localId === props.localFolderID
                                )
                                if (!sharedFolder) return
                                navigate(
                                    `/settings/${sharedFolder.remoteId}/invite`
                                )
                            }}
                        />
                    </footer>
                </section>
                <section className="flex flex-col justify-between bg-primary-light rounded lg:rounded-none lg:rounded-tr lg:rounded-br pb-8 px-4 sm:px-16 md:px-32 lg:px-16 xl:px-32 2xl:px-40 lg:pb-24 lg:w-1/2">
                    <h2 className="text-xl font-bold text-center mt-16 pb-14 sm:mt-20">
                        What happens next?
                    </h2>
                    <h3 className="text-xl font-semibold text-center pb-8 lg:mt-0 lg:text-left mask-text">
                        On every device where you want to access&nbsp;
                        {props.folderName
                            ? `"${props.folderName}"`
                            : 'your folder'}
                        , simply:
                    </h3>
                    <ol className="h-40 w-full mb-16 lg:mb-8">
                        <li className="flex justify-between  pb-8">
                            <div className="flex items-center justify-center w-full">
                                <p className="text-6xl lg:text-5xl font-semibold text-primary select-none">
                                    1
                                </p>
                                <p className="ml-2 text-xl font-semibold leading-6 break-words w-full">
                                    Login to Bookmark Llama as "
                                    {props.user?.email ?? ''}"
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-between pb-8 ">
                            <div className="flex items-center justify-center">
                                <p className="text-6xl lg:text-5xl font-semibold text-primary select-none">
                                    2
                                </p>
                                <p className="ml-2 text-xl font-semibold leading-6">
                                    Install the extension
                                </p>
                            </div>
                        </li>
                    </ol>
                    <footer>
                        <p className="text-xl font-semibold text-center sm:mt-8">
                            Got more folders to sync?
                        </p>
                        <Button
                            buttonText="Sync another folder"
                            buttonType={ButtonType.button}
                            disabled={false}
                            buttonTheme={ButtonTheme.tertiary}
                            className="w-full sm:max-w-max sm:px-4 text-xl lg:mb-[70px] mx-auto font-semibold hover:bg-indigo-100"
                            onClick={() => {
                                //This is a hacky way to restart the wizard,
                                //we must do this because the wizard doesn't have any url support
                                navigate(0)
                            }}
                        />
                    </footer>
                </section>
            </div>
        </div>
    )
}
