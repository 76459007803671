import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Button, { ButtonType } from '../customComponents/Button'
import { AlertType, ButtonTheme } from '../constants/enum'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
    runSyncEngine,
    setSharedFolderEncryptionPasswordMessage,
} from '../../extensionCommunication/messager'
import Alert from '../customComponents/Alert'
import InputPassword from '../customComponents/InputPassword'

interface ModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    folderId: number
    passwordSalt?: string
    getSharedFolders: () => void
}

interface EncryptionPasswordInputs {
    password: string
}
export default function EncryptionModal(props: ModalProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
    } = useForm<EncryptionPasswordInputs>()

    const { open, setOpen, getSharedFolders } = props
    const cancelButtonRef = useRef(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const decryptFolder = async (
        sharedFolderId: number,
        password: string,
        passwordSalt?: string
    ) => {
        if (!passwordSalt)
            throw new Error('expected password salt for decryption, got null')

        setIsLoading(true)

        await setSharedFolderEncryptionPasswordMessage(
            password,
            passwordSalt,
            sharedFolderId
        )
            .then(() => {
                runSyncEngine().then(() => {
                    resetField('password')
                    getSharedFolders()
                    setIsLoading(false)
                    setOpen(!open)
                    setError(null)
                })
            })
            .catch((e) => {
                if (e instanceof Error) {
                    if (e.message === '401 - Invalid Password') {
                        setError("Oops, that password didn't work. Try again?")
                    } else {
                        setError(
                            'Oops, something went wrong...please try again later!'
                        )
                    }
                }
                setIsLoading(false)
            })
    }

    const onSubmit: SubmitHandler<EncryptionPasswordInputs> = async (data) => {
        await decryptFolder(props.folderId, data.password, props.passwordSalt)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className=" bg-white px-4 pt-5 pb-4 sm:p-8">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 w-full text-center sm:mx-4 sm:mt-0 sm:text-left">
                                            <>
                                                {error && (
                                                    <Alert
                                                        alertType={
                                                            AlertType.failure
                                                        }
                                                        alertText={error || ''}
                                                        showAlert={!!error}
                                                        className="mb-4"
                                                    />
                                                )}
                                                <Dialog.Title
                                                    as="h3"
                                                    className="mb-4 text-lg font-semibold"
                                                >
                                                    Password Required
                                                </Dialog.Title>

                                                <div className="my-2">
                                                    <p className="text-lg text-slate-600">
                                                        Your folder is
                                                        encrypted. Please enter
                                                        your password to unlock
                                                        it.
                                                    </p>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <form
                                    className="flex w-full flex-col items-end"
                                    onSubmit={handleSubmit(onSubmit)}
                                    aria-disabled={isLoading}
                                >
                                    <div className="relative flex w-full flex-col px-4 pb-4 sm:px-12 sm:pb-12">
                                        <InputPassword
                                            labelText="Folder password*"
                                            register={{
                                                ...register('password', {
                                                    required: true,
                                                }),
                                            }}
                                            errors={errors.password}
                                            isLoading={isLoading}
                                            inputType="encryption-modal-password"
                                            showPasswordIconClass="bottom-[48%] right-[6%]"
                                            containerClass="w-full"
                                            customElement={
                                                <div className="flex justify-between">
                                                    {errors.password ? (
                                                        <span
                                                            role="alert"
                                                            className="block font-semibold text-red-800 selection:bg-none"
                                                        >
                                                            This field is
                                                            required
                                                        </span>
                                                    ) : (
                                                        <span className="text-base">
                                                            Must be at least 6
                                                            characters long
                                                        </span>
                                                    )}
                                                </div>
                                            }
                                        />
                                    </div>

                                    <div className="flex w-full flex-col bg-slate-50 px-4 py-3 sm:flex-row-reverse sm:items-center sm:px-6">
                                        <Button
                                            buttonTheme={ButtonTheme.primary}
                                            buttonText="Unlock folder"
                                            className="w-full sm:max-w-max sm:px-8"
                                            buttonType={ButtonType.submit}
                                            disabled={isLoading}
                                        />
                                        <Button
                                            buttonType={ButtonType.button}
                                            buttonTheme={ButtonTheme.tertiary}
                                            buttonText="Cancel"
                                            className="w-full sm:mr-2 sm:max-w-max sm:px-8"
                                            onClick={() => {
                                                setOpen(!open)
                                                resetField('password')
                                                setError(null)
                                            }}
                                            disabled={isLoading}
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
