/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostNewSharedFolderInvoicePreviewRequest
 */
export interface PostNewSharedFolderInvoicePreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof PostNewSharedFolderInvoicePreviewRequest
     */
    users: number;
}

/**
 * Check if a given object implements the PostNewSharedFolderInvoicePreviewRequest interface.
 */
export function instanceOfPostNewSharedFolderInvoicePreviewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function PostNewSharedFolderInvoicePreviewRequestFromJSON(json: any): PostNewSharedFolderInvoicePreviewRequest {
    return PostNewSharedFolderInvoicePreviewRequestFromJSONTyped(json, false);
}

export function PostNewSharedFolderInvoicePreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostNewSharedFolderInvoicePreviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': json['users'],
    };
}

export function PostNewSharedFolderInvoicePreviewRequestToJSON(value?: PostNewSharedFolderInvoicePreviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': value.users,
    };
}

