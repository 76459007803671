import { bookmarks, extensionState } from '../extensionCommunication/messager'
import Section from '../helpers/commonComponents/Section'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { ArrowLeftIcon, ArrowPathIcon } from '@heroicons/react/24/outline'
import {
    ChromeSvgIcon,
    EdgeSvgIcon,
    FirefoxSvgIcon,
} from '../helpers/icons/BrowserSvgIcons'
import {
    CHROME_EXTENSION_URL,
    EDGE_EXTENSION_URL,
    FIREFOX_EXTENSION_URL,
} from '../helpers/constants/constants'
import { setExtensionInstallOpenedParam } from '../helpers/helperFunctions'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import { RadioGroup } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'
import { uiText } from '../uiText/uiText'
import React, { useState } from 'react'
interface BookmarksSectionProps {
    formErrorsLocalFolderId: string | undefined
    bookmarks: null | bookmarks[]
    extensionState: extensionState | null
    isLoading: boolean
    localFolderId: string
    handleBookmarkFolderSelect: (localFolderId: string) => void
    sectionTitle?: string
    className?: string
    onRefresh?: () => void
}

export default function BookmarksSection(props: BookmarksSectionProps) {
    const {
        formErrorsLocalFolderId,
        bookmarks,
        extensionState,
        isLoading,
        localFolderId,
        handleBookmarkFolderSelect,
        sectionTitle,
        className,
    } = props
    let [_, setSearchParams] = useSearchParams()
    const [showExtensionPollingSpinner, setShowExtensionPollingSpinner] =
        useState<boolean>(false)
    const renderBookmarksSection = () => {
        if (!extensionState?.isInstalled) {
            return (
                <>
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            uiText.SharedFolder.missingExtensionAlertText
                        }
                        showAlert
                    />
                    <div
                        className={`${
                            showExtensionPollingSpinner
                                ? 'visible'
                                : 'invisible'
                        } flex justify-center my-10`}
                    >
                        <SpinnerSvgIcon width="40" height="40" />
                    </div>
                    <div className="mt-6 flex flex-col justify-around sm:flex-row">
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Firefox"
                            disabled={isLoading}
                            className="w-full sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <FirefoxSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(FIREFOX_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Chrome"
                            buttonTheme={ButtonTheme.secondary}
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonIcon={
                                <ChromeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(CHROME_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                        <Button
                            buttonType={ButtonType.button}
                            buttonText="Edge"
                            disabled={isLoading}
                            className="mt-2 w-full sm:mt-0 sm:w-[30%]"
                            buttonTheme={ButtonTheme.secondary}
                            buttonIcon={
                                <EdgeSvgIcon
                                    height="32"
                                    width="32"
                                    className="mr-2"
                                />
                            }
                            onClick={() => {
                                window.open(EDGE_EXTENSION_URL)
                                setExtensionInstallOpenedParam(setSearchParams)
                                setShowExtensionPollingSpinner(true)
                            }}
                        />
                    </div>
                </>
            )
        }

        if (!extensionState?.authenticatedUserId) {
            return (
                <div className="flex h-52 w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            )
        }

        if (bookmarks?.length === 0) {
            return (
                <>
                    {props.onRefresh && (
                        <Button
                            buttonType={ButtonType.button}
                            buttonTheme={ButtonTheme.tertiary}
                            buttonIcon={
                                <ArrowPathIcon className="h-6 w-6 flex mr-4 stroke-2 cursor-pointer" />
                            }
                            onClick={props.onRefresh}
                            className="h-8 px-3 mb-2"
                            buttonText="Refresh bookmarks"
                        ></Button>
                    )}
                    <Alert
                        alertType={AlertType.warning}
                        alertText={
                            <p>
                                We couldn't locate any bookmark folders on your
                                bookmark bar. Please enable the bookmark bar and
                                ensure the bookmarks you wish to share are
                                added.{' '}
                                <a
                                    href={
                                        'https://www.bookmarkllama.com/blog/show-bookmark-bar?utm_source=webapp_no_shared_folders'
                                    }
                                    target={'_blank'}
                                    className={
                                        'cursor-pointer font-bold underline hover:no-underline'
                                    }
                                >
                                    Need help enabling the bookmark bar?
                                </a>
                            </p>
                        }
                        showAlert
                    />
                </>
            )
        }

        if (bookmarks)
            return (
                <RadioGroup
                    value={localFolderId}
                    onChange={handleBookmarkFolderSelect}
                    disabled={isLoading}
                    className="max-h-[280px] overflow-y-auto bookmark-scrollbar"
                >
                    <RadioGroup.Label className="sr-only">
                        Share Bookmarks Folder
                    </RadioGroup.Label>
                    <>
                        {props.onRefresh && (
                            <Button
                                buttonType={ButtonType.button}
                                buttonTheme={ButtonTheme.tertiary}
                                buttonIcon={
                                    <ArrowPathIcon className="h-6 w-6 flex mr-4 stroke-2 cursor-pointer" />
                                }
                                onClick={props.onRefresh}
                                className="h-8 px-3 mb-2"
                                buttonText="Refresh bookmarks"
                            ></Button>
                        )}
                        <div className="flex flex-col items-center md:flex-row md:flex-wrap md:justify-between">
                            {bookmarks?.map(
                                (option) =>
                                    !option.isShared && (
                                        <RadioGroup.Option
                                            key={option.localId}
                                            value={option.localId}
                                            className={({ checked }) =>
                                                `${
                                                    isLoading &&
                                                    'cursor-not-allowed hover:ring-1 hover:ring-slate-300'
                                                } mask-text my-2 flex h-16 w-[98%] cursor-pointer items-center rounded p-4 hover:ring-2 hover:ring-primary active:ring-2 active:ring-primary md:w-[46%] md:mx-2 lg:px-8  ${
                                                    checked
                                                        ? 'bg-primary-light bg-opacity-25 ring-2 ring-primary'
                                                        : 'bg-white ring-1 ring-slate-300'
                                                }
                                                
                                                ${
                                                    isLoading &&
                                                    'mask-text ring-1 hover:cursor-not-allowed hover:ring-1 hover:ring-slate-300 active:ring-1 active:ring-slate-300'
                                                }`
                                            }
                                        >
                                            {({ checked }) => (
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`${
                                                        checked
                                                            ? 'font-bold text-primary'
                                                            : ''
                                                    } text-ellipses mask-text truncate text-lg font-semibold`}
                                                >
                                                    {option.name}
                                                </RadioGroup.Label>
                                            )}
                                        </RadioGroup.Option>
                                    )
                            )}
                        </div>
                    </>
                </RadioGroup>
            )
        return (
            <div>
                <Alert
                    showAlert
                    alertText="Oops, something went wrong!"
                    alertType={AlertType.failure}
                />
            </div>
        )
    }
    return (
        <div className={className}>
            <Section
                sectionTitle={sectionTitle}
                error={formErrorsLocalFolderId}
                emptySection={
                    bookmarks?.length === 0 || !extensionState?.isInstalled
                }
            >
                {renderBookmarksSection()}
            </Section>
        </div>
    )
}
