/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSharedFolderUserPermissionRequest
 */
export interface PostSharedFolderUserPermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderUserPermissionRequest
     */
    permission: PostSharedFolderUserPermissionRequestPermissionEnum;
}


/**
 * @export
 */
export const PostSharedFolderUserPermissionRequestPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;
export type PostSharedFolderUserPermissionRequestPermissionEnum = typeof PostSharedFolderUserPermissionRequestPermissionEnum[keyof typeof PostSharedFolderUserPermissionRequestPermissionEnum];


/**
 * Check if a given object implements the PostSharedFolderUserPermissionRequest interface.
 */
export function instanceOfPostSharedFolderUserPermissionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "permission" in value;

    return isInstance;
}

export function PostSharedFolderUserPermissionRequestFromJSON(json: any): PostSharedFolderUserPermissionRequest {
    return PostSharedFolderUserPermissionRequestFromJSONTyped(json, false);
}

export function PostSharedFolderUserPermissionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderUserPermissionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permission': json['permission'],
    };
}

export function PostSharedFolderUserPermissionRequestToJSON(value?: PostSharedFolderUserPermissionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permission': value.permission,
    };
}

