import { Fragment, useEffect, useState } from 'react'
import Section from '../helpers/commonComponents/Section'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import {
    useCurrentSharedFolder,
    useCurrentUser,
    useFolderUsers,
    useGetCurrentSharedFolder,
    useGetSharedFolderUsers,
} from './Settings'
import { Tooltip } from 'react-tooltip'
import { Menu } from '@headlessui/react'
import ChevronDownIcon from '@heroicons/react/24/outline/ChevronDownIcon'
import {
    AcceptedUsers,
    PendingUsers,
} from '../helpers/commonComponents/Members'
import apiHelper from '../apiClient/defaultApiClient'
import {
    GetSharedFolderAssignments200ResponseInner,
    PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum as PermissionsEnum,
    User,
} from '../apiClient'
import { toastError } from '../helpers/commonComponents/toastHelper'
import { useNavigate } from 'react-router-dom'

// OWNER (formerly known as creator) cannot modify permissions for themselves
// No one can modify permissions for the owner or themselves

export default function Permissions() {
    interface PermissionsOptions {
        label: string
        value: PermissionsEnum
    }

    enum PermissionsLabel {
        read = 'Read-only',
        write = 'Editor',
    }
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { folderUsers } = useFolderUsers()
    const { currentFolder } = useCurrentSharedFolder()
    const [defaultPermissions, setDefaultPermissions] = useState<
        PermissionsOptions | undefined
    >(undefined)
    const { getSharedFolderUsers } = useGetSharedFolderUsers()
    const { getSharedFolder } = useGetCurrentSharedFolder()
    const { user } = useCurrentUser()
    const [currentFolderUser, setCurrentFolderUser] = useState<
        GetSharedFolderAssignments200ResponseInner | undefined
    >(undefined)
    const navigate = useNavigate()

    const isCurrentUserCreator = user?.id === currentFolder?.createdBy

    const permissionsOptions = [
        {
            label: PermissionsLabel.read,
            value: PermissionsEnum.Read,
        },
        {
            label: PermissionsLabel.write,
            value: PermissionsEnum.Write,
        },
    ]

    const getFolderCurrentUser = () => {
        return folderUsers?.find((folderUser) => folderUser.userId === user?.id)
    }

    useEffect(() => {
        const currentUser = getFolderCurrentUser()
        if (currentFolderUser !== currentUser) setCurrentFolderUser(currentUser)
    }, [folderUsers])
    // Checks if folderUsers changed to update current user

    useEffect(() => {
        const defaultPermissions = currentFolder?.defaultPermission
        defaultPermissions === PermissionsEnum.Write
            ? setDefaultPermissions({
                  label: PermissionsLabel.write,
                  value: PermissionsEnum.Write,
              })
            : setDefaultPermissions({
                  label: PermissionsLabel.read,
                  value: PermissionsEnum.Read,
              })
    }, [currentFolder])
    // Checks if the folder changed to update default permissions

    const postDefaultPermissions = async (option: PermissionsOptions) => {
        setIsLoading(true)
        const folderId = currentFolder?.id
        if (folderId) {
            await apiHelper
                .postSharedFolderDefaultPermission({
                    folderId: folderId,
                    body: { defaultPermission: option.value },
                })
                .then(() => {
                    setDefaultPermissions(option)
                    getSharedFolder({ folderID: folderId })
                    setIsLoading(false)
                })
                .catch(() => {
                    toastError(
                        "Oops, something went wrong, and we couldn't update your folder's default permissions."
                    )
                    setIsLoading(false)
                })
        } else {
            toastError(
                "Oops, something went wrong, and we couldn't update your folder's default permissions."
            )
        }
    }

    const postUserPermissions = async (
        option: PermissionsOptions,
        userId?: number
    ) => {
        setIsLoading(true)
        const folderId = currentFolder?.id
        if (folderId && userId) {
            await apiHelper
                .postSharedFolderUserPermission({
                    folderId: folderId,
                    userId: userId,
                    body: { permission: option.value },
                })
                .then(() => {
                    getSharedFolderUsers({
                        sharedFolderId: folderId,
                    })
                    getSharedFolder({ folderID: folderId })
                    setIsLoading(false)
                })
                .catch(() => {
                    toastError(
                        "Oops, something went wrong, and we couldn't update your user's permissions."
                    )
                    setIsLoading(false)
                })
        } else {
            toastError(
                "Oops, something went wrong, and we couldn't update your user's permissions."
            )
        }
    }

    const renderMenu = (
        currentButtonLabel:
            | PermissionsOptions['label']
            | PermissionsEnum
            | undefined,
        setPermissions: (option: PermissionsOptions, userId?: number) => void,
        userId?: number
    ) => {
        const getPermissionsLabel = () => {
            if (
                currentButtonLabel === PermissionsEnum.Read ||
                (!currentButtonLabel &&
                    defaultPermissions?.value === PermissionsEnum.Read)
            ) {
                return PermissionsLabel.read
            } else if (
                currentButtonLabel === PermissionsEnum.Write ||
                (!currentButtonLabel &&
                    defaultPermissions?.value === PermissionsEnum.Write)
            ) {
                return PermissionsLabel.write
            }
        }
        const isUserCreator = userId === currentFolder?.createdBy
        const currentUser = currentFolderUser?.userId === userId

        return currentUser || isUserCreator ? (
            <p className=" sm:mr-4 italic">
                {isUserCreator && 'Owner,'}&nbsp;
                {getPermissionsLabel()}
            </p>
        ) : (
            <Menu
                as="div"
                className="relative inline-block rounded text-left"
                data-testid="permission_toggle"
            >
                <Menu.Button className="inline-flex h-12 items-center justify-center rounded sm:px-4 sm:py-2 font-semibold text-primary hover:bg-indigo-100 hover:bg-opacity-25 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary z-30 ">
                    <p className="hidden md:block sm:w-24 text-right">
                        {getPermissionsLabel()}
                    </p>
                    <ChevronDownIcon
                        className="sm:ml-2 h-5 w-5 stroke-[3px]"
                        aria-hidden="true"
                    />
                </Menu.Button>
                <div className="absolute w-40 sm:flex sm:justify-center z-40 mt-1 right-[1px]">
                    <Menu.Items className="rounded-md  bg-white font-medium shadow focus:outline-none  ">
                        {permissionsOptions.map((option) => (
                            <Menu.Item key={option.value} as={Fragment}>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active &&
                                            'bg-indigo-50  text-slate-900 cursor-pointer'
                                        } p-3 w-full text-left pl-5`}
                                        onClick={
                                            userId
                                                ? () =>
                                                      setPermissions(
                                                          option,
                                                          userId
                                                      )
                                                : () => setPermissions(option)
                                        }
                                    >
                                        {option.label}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.Items>
                </div>
            </Menu>
        )
    }

    return (
        <Section className="mt-8 box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-32 text-lg">
            {isLoading ? (
                <div className="flex w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            ) : (
                <div>
                    <div className="flex justify-between items-center border-b border-slate-300 pb-4 mb-6">
                        <header className="flex items-center">
                            <h2 className="font-bold mr-3 leading-5 w-auto">
                                Default permissions
                            </h2>
                            <a
                                className="z-40 w-8 h-8 border-2 border-primary rounded-full flex justify-center items-center self-end text-primary font-semibold text-xl cursor-pointer"
                                data-tooltip-id="permissions-tooltip"
                                data-tooltip-place="bottom"
                                data-tooltip-content={`${
                                    isCurrentUserCreator
                                        ? 'Newly added users and those without specific permissions will be given this permission.'
                                        : 'Only the folder owner can modify default permissions.'
                                } `}
                            >
                                ?<Tooltip id="permissions-tooltip" />
                            </a>
                        </header>
                        {/* Only render menu if the current user is the owner */}
                        {isCurrentUserCreator ? (
                            renderMenu(
                                defaultPermissions?.value,
                                postDefaultPermissions
                            )
                        ) : (
                            <p className="mr-3 font-semibold">
                                {defaultPermissions?.label}
                            </p>
                        )}
                    </div>

                    {/* TO DO: plug in logic to individually select who has editing rights */}
                    <ul>
                        {folderUsers.map((user) => (
                            <li
                                className="flex items-center justify-between mb-4 text-base sm:text-lg"
                                key={user.userId}
                            >
                                {user.isPending ? (
                                    <PendingUsers user={user} />
                                ) : (
                                    <AcceptedUsers user={user} />
                                )}
                                {renderMenu(
                                    user.permission,
                                    postUserPermissions,
                                    user.userId
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </Section>
    )
}
