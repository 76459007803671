import { useNavigate } from 'react-router-dom'
import { ButtonTheme } from '../constants/enum'
import Button from '../customComponents/Button'
import notFoundImg from '../../assets/not-found.png'

export default function NotFound() {
    const navigate = useNavigate()
    return (
        <div className="items:center flex h-full w-full flex-col-reverse justify-center pb-10 md:flex-row">
            <div className="relative flex h-full w-full items-center justify-center rounded-3xl md:w-[45%] lg:w-[55%]">
                <div className="absolute inset-0" />
                <div
                    className="h-full w-full bg-cover bg-center rounded-3xl bg-no-repeat"
                    style={{ backgroundImage: `url('${notFoundImg}')` }}
                />
            </div>
            <div className="flex w-full flex-col items-center justify-center md:w-[55%] md:px-4 lg:w-[45%] my-16 md:mt-0 md:mb-24">
                <h2 className="text-7xl font-black text-primary">404</h2>
                <p className="mt-2 mb-20 text-xl font-semibold">
                    Page not found
                </p>
                <Button
                    buttonTheme={ButtonTheme.primary}
                    buttonText="Go to homepage"
                    onClick={() => navigate('/')}
                    className="w-full sm:max-w-max sm:px-20 md:w-full lg:max-w-max lg:px-20"
                />
            </div>
        </div>
    )
}
