import { useState, useEffect, FormEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { PostLoginRequest } from '../apiClient'
import apiHelper from '../apiClient/defaultApiClient'
import { ButtonTheme, NotificationType } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { UNAUTHORIZED_ERROR } from '../helpers/constants/constants'
import Modal from '../helpers/commonComponents/Modal'
import marketingImage from '../assets/login.png'
import hasSessionToken from '../helpers/hooks/hasSessionToken'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastInfo,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import Input from '../helpers/customComponents/Input'
import InputPassword from '../helpers/customComponents/InputPassword'
import {
    GoogleIcon,
    MicrosoftIcon,
} from '../helpers/icons/ThirdPartySignInIcons'
import { useGoogleLogin } from '@react-oauth/google'
import { useMsal } from '@azure/msal-react'

interface LoginFormInputs {
    email: string
    password: string
}

export default function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormInputs>()

    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('')
    const [forgotPasswordEmailError, setForgotPasswordEmailError] =
        useState<boolean>(false)
    const [isForgotPasswordLoading, setIsForgotPasswordLoading] =
        useState<boolean>(false)
    const [openFakeDoorModal, setOpenFakeDoorModal] = useState<boolean>(false)
    const { instance } = useMsal()

    const loginGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setIsLoading(true)
            apiHelper
                .postLoginGoogleRaw({
                    body: {
                        code: codeResponse.code,
                        scope: codeResponse.scope,
                    },
                })
                .then(async (data) => {
                    const resp = await data.raw.json()
                    localStorage.setItem('sessionToken', resp.session_token)
                    //response code 201 means we had to register the user
                    navigate('/', {
                        state: { register: data.raw.status === 201 },
                    })
                })
        },
        flow: 'auth-code',
    })

    useEffect(() => {
        const notificationType = location.state?.notification
        if (notificationType) {
            const currentURL = `${location.pathname}${location.search}`
            if (notificationType === NotificationType.resetPasswordSuccess) {
                toastSuccess(uiText.Notifications.success.resetPassword)
            }
            if (notificationType === NotificationType.unAuthorizedProfile) {
                toastInfo(uiText.Notifications.error.unauthorizedProfile)
            }
            navigate(currentURL, { state: undefined })
        } else return
    }, [])

    const forgotPassword = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (forgotPasswordEmailError) return

        if (forgotPasswordEmail) {
            try {
                setIsForgotPasswordLoading(true)
                await apiHelper
                    .v3ForgotPassword({
                        body: { emailAddress: forgotPasswordEmail },
                    })
                    .then(() => {
                        setForgotPasswordEmail('')
                        setForgotPasswordEmailError(false)
                        setIsForgotPasswordLoading(false)
                        setOpen(false)
                        toastSuccess(
                            uiText.Notifications.success.emailToResetPassword
                        )
                    })
            } catch (e: any) {
                setIsForgotPasswordLoading(false)
                setOpen(!open)
                toastError(uiText.Notifications.error.emailToResetPassword)
            }
        } else {
            setForgotPasswordEmailError(true)
        }
    }

    const postLogin = async (data: PostLoginRequest) => {
        try {
            setIsLoading(true)
            await apiHelper
                .postLogin({
                    body: data,
                })
                .then((data) => {
                    localStorage.setItem('sessionToken', data.sessionToken)
                    navigate('/')
                })
        } catch (e: any) {
            if (e.response?.status === UNAUTHORIZED_ERROR) {
                toastError(uiText.Notifications.error.emailOrPasswordIncorrect)
            } else {
                toastError(uiText.Notifications.error.generic)
            }
            setIsLoading(false)
        }
    }

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        postLogin(data)
    }

    const onChangeForgotPasswordEmail = (e: string) => {
        if (forgotPasswordEmailError) setForgotPasswordEmailError(false)
        setForgotPasswordEmail(e)
    }

    if (hasSessionToken()) return <Navigate to="/" replace />
    return (
        <div className="h-screen">
            <div className="md:bg-gradient-to-r from-background to-50% to-indigo-50 from-50%  min-h-full border">
                <BookmarkLlamaDesktopIcon className={'mx-4 pt-4 lg:mx-5'} />
                <div className="flex justify-center  ">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="px-4 h-full w-full md:h-2/3 rounded md:shadow-lg md:border bg-background md:w-4/5 xl:w-2/3 md:p-4 md:my-8"
                    >
                        <h1 className="text-3xl font-medium mt-14 text-center">
                            Nice to see you again!
                        </h1>

                        <div className="flex flex-col justify-center items-center  lg:flex-row-reverse lg:items-start mt-16">
                            <div className="w-72 sm:w-96 lg:w-80 2xl:w-96 mx-auto lg:mx-0 mt-2">
                                <Button
                                    buttonText={'Continue with Google'}
                                    disabled={isLoading}
                                    buttonType={ButtonType.button}
                                    className="w-72 sm:w-96 lg:w-80 2xl:w-96 text-default"
                                    buttonTheme={ButtonTheme.secondaryDark}
                                    buttonIcon={<GoogleIcon />}
                                    onClick={loginGoogle}
                                />

                                <Button
                                    buttonText={'Continue with Microsoft'}
                                    disabled={isLoading}
                                    buttonType={ButtonType.button}
                                    className=" text-default mt-4 w-72 sm:w-96 lg:w-80 2xl:w-96"
                                    buttonTheme={ButtonTheme.secondaryDark}
                                    buttonIcon={<MicrosoftIcon />}
                                    onClick={() =>
                                        instance
                                            .loginPopup({
                                                scopes: ['User.Read'],
                                            })
                                            .catch((e) => {
                                                console.log(e)
                                            })
                                            .then((res) => {
                                                if (!res) return
                                                setIsLoading(true)
                                                apiHelper
                                                    .postLoginMicrosoftRaw({
                                                        body: {
                                                            code: res.accessToken,
                                                        },
                                                    })
                                                    .then(async (data) => {
                                                        const resp =
                                                            await data.raw.json()
                                                        localStorage.setItem(
                                                            'sessionToken',
                                                            resp.session_token
                                                        )
                                                        //response code 201 means we had to register the user
                                                        navigate('/', {
                                                            state: {
                                                                register:
                                                                    data.raw
                                                                        .status ===
                                                                    201,
                                                            },
                                                        })
                                                    })
                                            })
                                    }
                                />
                            </div>
                            <p className="font-semibold text-lg text-center my-6 lg:mx-10 lg:my-12">
                                or
                            </p>

                            <div className="flex flex-col items-center h-full">
                                <Input
                                    labelText="Email*"
                                    register={register('email', {
                                        required: true,
                                    })}
                                    errors={errors.email}
                                    isLoading={isLoading}
                                    inputType="login-email"
                                    errorText="This field is required"
                                    className="w-72 sm:w-96 lg:w-80 2xl:w-96"
                                />

                                {/* Password */}
                                <InputPassword
                                    labelText="Password*"
                                    register={{
                                        ...register('password', {
                                            required: true,
                                            maxLength: 72,
                                        }),
                                    }}
                                    showPasswordIconClass="bottom-[51%] right-[6%] "
                                    inputClass={'w-72 sm:w-96 lg:w-80 2xl:w-96'}
                                    errors={errors.password}
                                    isLoading={isLoading}
                                    inputType="login-password"
                                    customElement={
                                        <div className="flex justify-between">
                                            {errors.password ? (
                                                <span
                                                    role="alert"
                                                    className="block font-semibold text-red-800 selection:bg-none"
                                                >
                                                    This field is required
                                                </span>
                                            ) : (
                                                <span className="text-base">
                                                    &nbsp;
                                                </span>
                                            )}
                                            <input
                                                disabled={isLoading}
                                                type="button"
                                                className="mt-1 cursor-pointer self-end rounded-md px-2 font-bold text-primary ring-primary hover:bg-primary-light focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-2"
                                                onClick={() => setOpen(!open)}
                                                value="Forgot password?"
                                            />
                                        </div>
                                    }
                                />
                                <Button
                                    buttonText={'Log in'}
                                    disabled={isLoading}
                                    buttonType={ButtonType.submit}
                                    className="my-8 w-72 sm:w-96 lg:w-80 2xl:w-96"
                                    buttonTheme={ButtonTheme.primary}
                                />
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <Link
                                to={'/register'}
                                className="inline-block text-center rounded-md px-2 ring-primary hover:bg-primary-light focus-visible:rounded-sm  focus-visible:outline-none focus-visible:ring-2 mb-16 sm:text-lg lg:mt-20"
                            >
                                <span className="font-semibold text-slate-600">
                                    Don't have an account?&nbsp;
                                    <span className="font-bold text-primary underline">
                                        Sign up
                                    </span>
                                </span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>

            <Modal
                open={open}
                setOpen={setOpen}
                modalTitle="Forgot your password? No problem!"
                modalText="Enter your email address, and we'll send an email with steps to reset
        your password."
                onSubmit={(e: FormEvent<HTMLFormElement>) => forgotPassword(e)}
                primaryButton={
                    <Button
                        buttonType={ButtonType.submit}
                        buttonText="Reset password"
                        buttonTheme={ButtonTheme.primary}
                        disabled={isForgotPasswordLoading}
                        className="px-8"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isForgotPasswordLoading}
                        className="mt-2 px-8 sm:mr-2 sm:mt-0"
                        onClick={() => {
                            setForgotPasswordEmail('')
                            setForgotPasswordEmailError(false)
                            setOpen(false)
                        }}
                    />
                }
                children={
                    <div className="my-8 flex w-full flex-col items-start">
                        <label className={`ml-2 font-semibold`} htmlFor="email">
                            Email*
                        </label>
                        <input
                            onChange={(e) =>
                                onChangeForgotPasswordEmail(e.target.value)
                            }
                            type="email"
                            aria-invalid={
                                forgotPasswordEmailError ? 'true' : 'false'
                            }
                            className={`h-12 w-full rounded-t border-b-2 border-default bg-background px-2 invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 focus-visible:invalid:bg-red-50 ${
                                forgotPasswordEmailError &&
                                'bg-50-red border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50 focus-visible:outline-none'
                            }`}
                            disabled={isForgotPasswordLoading}
                        />
                        {forgotPasswordEmailError ? (
                            <span
                                role="alert"
                                className="font-semibold text-red-800 selection:bg-none"
                            >
                                This field is required
                            </span>
                        ) : (
                            <span
                                role="alert"
                                className="text-base text-slate-900"
                            >
                                &nbsp;
                            </span>
                        )}
                    </div>
                }
            />
        </div>
    )
}
