/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SharedFolder
 */
export interface SharedFolder {
    /**
     * 
     * @type {number}
     * @memberof SharedFolder
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SharedFolder
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SharedFolder
     */
    createdBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SharedFolder
     */
    encrypted: boolean;
    /**
     * 
     * @type {string}
     * @memberof SharedFolder
     */
    passwordSalt?: string;
    /**
     * 
     * @type {number}
     * @memberof SharedFolder
     */
    organisationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SharedFolder
     */
    isPremium: boolean;
    /**
     * 
     * @type {string}
     * @memberof SharedFolder
     */
    defaultPermission: SharedFolderDefaultPermissionEnum;
}


/**
 * @export
 */
export const SharedFolderDefaultPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;
export type SharedFolderDefaultPermissionEnum = typeof SharedFolderDefaultPermissionEnum[keyof typeof SharedFolderDefaultPermissionEnum];


/**
 * Check if a given object implements the SharedFolder interface.
 */
export function instanceOfSharedFolder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "encrypted" in value;
    isInstance = isInstance && "isPremium" in value;
    isInstance = isInstance && "defaultPermission" in value;

    return isInstance;
}

export function SharedFolderFromJSON(json: any): SharedFolder {
    return SharedFolderFromJSONTyped(json, false);
}

export function SharedFolderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedFolder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'encrypted': json['encrypted'],
        'passwordSalt': !exists(json, 'password_salt') ? undefined : json['password_salt'],
        'organisationId': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'isPremium': json['is_premium'],
        'defaultPermission': json['default_permission'],
    };
}

export function SharedFolderToJSON(value?: SharedFolder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created_by': value.createdBy,
        'encrypted': value.encrypted,
        'password_salt': value.passwordSalt,
        'organisation_id': value.organisationId,
        'is_premium': value.isPremium,
        'default_permission': value.defaultPermission,
    };
}

