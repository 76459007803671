/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoicePreviewProrated } from './InvoicePreviewProrated';
import {
    InvoicePreviewProratedFromJSON,
    InvoicePreviewProratedFromJSONTyped,
    InvoicePreviewProratedToJSON,
} from './InvoicePreviewProrated';
import type { InvoicePreviewTax } from './InvoicePreviewTax';
import {
    InvoicePreviewTaxFromJSON,
    InvoicePreviewTaxFromJSONTyped,
    InvoicePreviewTaxToJSON,
} from './InvoicePreviewTax';

/**
 * 
 * @export
 * @interface InvoicePreview
 */
export interface InvoicePreview {
    /**
     * 
     * @type {string}
     * @memberof InvoicePreview
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicePreview
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePreview
     */
    unitPrice: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePreview
     */
    quantity: number;
    /**
     * 
     * @type {InvoicePreviewTax}
     * @memberof InvoicePreview
     */
    tax?: InvoicePreviewTax;
    /**
     * 
     * @type {InvoicePreviewProrated}
     * @memberof InvoicePreview
     */
    prorated?: InvoicePreviewProrated;
}

/**
 * Check if a given object implements the InvoicePreview interface.
 */
export function instanceOfInvoicePreview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "unitPrice" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function InvoicePreviewFromJSON(json: any): InvoicePreview {
    return InvoicePreviewFromJSONTyped(json, false);
}

export function InvoicePreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicePreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': json['currency'],
        'total': json['total'],
        'unitPrice': json['unit_price'],
        'quantity': json['quantity'],
        'tax': !exists(json, 'tax') ? undefined : InvoicePreviewTaxFromJSON(json['tax']),
        'prorated': !exists(json, 'prorated') ? undefined : InvoicePreviewProratedFromJSON(json['prorated']),
    };
}

export function InvoicePreviewToJSON(value?: InvoicePreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': value.currency,
        'total': value.total,
        'unit_price': value.unitPrice,
        'quantity': value.quantity,
        'tax': InvoicePreviewTaxToJSON(value.tax),
        'prorated': InvoicePreviewProratedToJSON(value.prorated),
    };
}

