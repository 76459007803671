import EnvelopeIcon from '@heroicons/react/24/outline/EnvelopeIcon'
import { GetSharedFolderAssignments200ResponseInner } from '../../apiClient'
interface User {
    user: GetSharedFolderAssignments200ResponseInner
}

export function AcceptedUsers(user: User) {
    return (
        <>
            <div className="h-10 w-10 sm:h-12 sm:w-12">
                <div className="mask-text ml-1 flex h-10 w-10 items-center justify-center rounded-full border bg-default text-2xl font-semibold text-white sm:h-12 sm:w-12">
                    {user.user.name
                        ? user.user.name[0].toUpperCase()
                        : user.user.email[0].toUpperCase()}
                </div>
            </div>
            <div className="w-[90%] px-3 leading-5 sm:px-6  box-border truncate text-base md:text-lg ">
                <p className="mask-text w-full font-bold text-ellipsis truncate">
                    {user.user.name}
                </p>
                <p className="mask-text box-border w-full text-ellipsis truncate">
                    {user.user.email}
                </p>
            </div>
        </>
    )
}

export function PendingUsers(user: User) {
    return (
        <>
            <div className="flex h-12 w-12 items-center justify-start rounded-full text-2xl sm:h-14 sm:w-14">
                <EnvelopeIcon
                    aria-hidden="true"
                    className="h-12 w-12 sm:h-14 sm:w-14"
                />
            </div>

            <div className="w-[85%] px-1 leading-5 sm:px-4 md:w-[90%] text-base md:text-lg truncate">
                <p className="mask-text text-ellipsis truncate">
                    {user?.user.email}
                </p>
            </div>
        </>
    )
}
