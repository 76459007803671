/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSharedFolderInvoicePreviewRequest
 */
export interface PostSharedFolderInvoicePreviewRequest {
    /**
     * 
     * @type {number}
     * @memberof PostSharedFolderInvoicePreviewRequest
     */
    newUsers: number;
}

/**
 * Check if a given object implements the PostSharedFolderInvoicePreviewRequest interface.
 */
export function instanceOfPostSharedFolderInvoicePreviewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newUsers" in value;

    return isInstance;
}

export function PostSharedFolderInvoicePreviewRequestFromJSON(json: any): PostSharedFolderInvoicePreviewRequest {
    return PostSharedFolderInvoicePreviewRequestFromJSONTyped(json, false);
}

export function PostSharedFolderInvoicePreviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderInvoicePreviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newUsers': json['new_users'],
    };
}

export function PostSharedFolderInvoicePreviewRequestToJSON(value?: PostSharedFolderInvoicePreviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_users': value.newUsers,
    };
}

