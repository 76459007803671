export const UNAUTHORIZED_ERROR = 401
export const NOT_FOUND_ERROR = 404

export const MAIL_TO_EMAIL = 'support@bookmarkllama.com'

export const CONTACT_PAGE = 'https://www.bookmarkllama.com/contact'

export const FREE_FOLDER_LIMIT = 2
export const ONE_USER_IN_FOLDER = 1
export const ONE_USER_IN_ORGANISATION = 1

export const ONE_USER_TO_ADD = 1
export const ZERO_USER_TO_ADD = 0
export const CREATOR = 1

export const PREMIUM_PRICE = 5
export const ORGANISATION_PRICE = 7

export const CHROME_EXTENSION_URL =
    'https://chrome.google.com/webstore/detail/bookmark-llama/mimpfplfhbocogjgjoiklgefbfkcflph'

export const FIREFOX_EXTENSION_URL =
    'https://addons.mozilla.org/en-GB/firefox/addon/bookmark-llama/'

export const EDGE_EXTENSION_URL =
    'https://microsoftedge.microsoft.com/addons/detail/bookmark-llama/ipnohflnkaljegnpiepbfefnnodmgkmp'

const lifeHackInnovations = [3343, 3342, 3341]

const projectiqSolutions = [4040, 4091, 4039]

const absoluteInvestimentos = [4442, 4444, 4445]

const stockGmail = [4493, 4494, 4495, 4496]

const agendaro = [5034, 5033, 5032, 4621, 4622, 4618, 4617]

const cohelpDesk = [4433, 4436, 4438, 4437, 4439]

const vayaGroup = [3794, 3795, 4897, 4896, 4898, 4899]

const careerrevolution = [4983, 4984]

const adeo = [4524, 4525, 4928]

const viasat = [5360, 5362, 5361, 5364, 5366, 5365, 5363]

const newGenericGmail = [5374, 5375, 5373]

const hydtec = [
    5547, 5506, 5505, 5507, 5508, 5509, 5516, 5517, 5519, 5512, 5518, 5515,
    5513, 5514,
]

const mpld = [5496, 5498, 5499]

const startreeai = [5628, 5631, 5476, 5475]

const thryvMedical = [5660, 5661, 5663, 5664, 5662]

const appomnia = [5748, 5749, 5750, 5751, 5752, 5754, 5755, 5753]

const rapptrlabs = [5761, 5760, 5149]

const tribe = [5816, 5818, 5817, 5819]

const operatus = [5853, 5851, 5854, 5852]

export const userIdsHandSelectedFreeTrialExpiry: number[] = [
    ...adeo,
    ...careerrevolution,
    ...lifeHackInnovations,
    ...projectiqSolutions,
    ...absoluteInvestimentos,
    ...stockGmail,
    ...agendaro,
    ...cohelpDesk,
    ...vayaGroup,
    ...viasat,
    ...newGenericGmail,
    ...hydtec,
    ...mpld,
    ...startreeai,
    ...thryvMedical,
    ...appomnia,
    ...rapptrlabs,
    ...tribe,
    ...operatus,
]

export const userIdsHandSelectedAccountSharing: number[] = []
