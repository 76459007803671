import { Link, Outlet, useMatches, useOutletContext } from 'react-router-dom'
import { Fragment, useEffect, useState } from 'react'
import { User } from '../apiClient'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useExtensionState, useUser } from '../dashboard/Controller'
import { extensionState } from '../extensionCommunication/messager'

enum MenuItem {
    account = 'Account',
    billing = 'Billing',
    support = 'Support',
    update = 'Password',
    delete = 'Delete Account',
}

type ContextTypeUser = { user: User | null }
type ContextTypeExtensionState = { extensionState: extensionState | null }

export default function Profile() {
    const [menuItem, setMenuItem] = useState<MenuItem | null>(null)
    const matches = useMatches()
    const accountMatch = matches.find((object) => object.id === 'account')
    const supportMatch = matches.find((object) => object.id === 'support')
    const billingMatch = matches.find((object) => object.id === 'billing')
    const updateMatch = matches.find((object) => object.id === 'password')
    const deleteMatch = matches.find((object) => object.id === 'delete')

    const MenuItems = [
        { name: MenuItem.account, url: 'account' },
        { name: MenuItem.billing, url: 'billing' },
        { name: MenuItem.support, url: 'support' },
        { name: MenuItem.update, url: 'update' },
        { name: MenuItem.delete, url: 'delete' },
    ]

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }
    const { user } = useUser()
    const { extensionState } = useExtensionState()

    useEffect(() => {
        if (accountMatch) {
            setMenuItem(MenuItem.account)
        } else if (deleteMatch) {
            setMenuItem(MenuItem.delete)
        } else if (supportMatch) {
            setMenuItem(MenuItem.support)
        } else if (billingMatch) {
            setMenuItem(MenuItem.billing)
        } else if (updateMatch) {
            setMenuItem(MenuItem.update)
        } else {
            setMenuItem(MenuItem.account)
        }
    }, [])

    return (
        <>
            <section className="mt-6 flex flex-col items-center md:mt-20 md:flex-row md:items-start">
                <Menu
                    as="div"
                    className="relative z-50 inline-block w-full rounded text-left md:hidden"
                >
                    <div className="w-full sm:flex sm:justify-center">
                        <Menu.Button className="text-semibold  inline-flex h-12 w-full items-center justify-center rounded border border-primary px-4 py-2 text-lg font-semibold text-primary hover:border-primary-hover hover:bg-indigo-50 hover:bg-opacity-25 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary sm:w-96">
                            {menuItem}
                            <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5 "
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="absolute w-full  sm:flex sm:justify-center">
                            <Menu.Items className=" mt-2 w-full rounded-md  bg-white font-medium shadow focus:outline-none sm:w-96">
                                <div>
                                    {MenuItems.map((item) => {
                                        return (
                                            <Menu.Item
                                                key={`profile-menu-item-${item.name}`}
                                            >
                                                {({ active }) => (
                                                    <Link
                                                        to={item.url}
                                                        className={classNames(
                                                            active &&
                                                                'bg-slate-100 text-slate-900',
                                                            'flex items-center px-4 py-2'
                                                        )}
                                                        onClick={() => {
                                                            setMenuItem(
                                                                item.name
                                                            )
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        )
                                    })}
                                </div>
                            </Menu.Items>
                        </div>
                    </Transition>
                </Menu>
                <nav className="hidden  w-40 flex-col border-r border-slate-900 md:flex">
                    {MenuItems.map((item) => {
                        return (
                            <Link
                                key={`profile-menu-item-${item.name}`}
                                to={item.url}
                                className={`${
                                    menuItem === item.name
                                        ? ' font-semibold text-primary'
                                        : 'font-semibold text-default hover:text-primary-hover'
                                } pb-6 text-lg`}
                                onClick={() => setMenuItem(item.name)}
                            >
                                {item.name}
                            </Link>
                        )
                    })}
                </nav>
                <Outlet context={{ user, extensionState }} />
            </section>
        </>
    )
}
export function useCurrentUser() {
    return useOutletContext<ContextTypeUser>()
}

export function useCurrentExtensionState() {
    return useOutletContext<ContextTypeExtensionState>()
}
