import { EnvelopeIcon } from '@heroicons/react/24/outline'
import Button, { ButtonType } from '../../helpers/customComponents/Button'
import { ButtonTheme } from '../../helpers/constants/enum'
import { bookmarks, getBookmarks } from '../../extensionCommunication/messager'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ShareConfirmationScreen(props: {
    invitedEmails: string[]
    folderName: string
    localFolderID: string
}) {
    const [bookmarks, setBookmarks] = useState<null | bookmarks[]>(null)
    const navigate = useNavigate()

    useEffect(() => {
        getBookmarks().then((b) => {
            setBookmarks(b)
        })
    }, [])

    return (
        <div className="pb-4 flex justify-center items-center min-h-[95%] lg:pb-2">
            <div className=" lg:flex lg:justify-between lg:shadow rounded min-h-full">
                <section className="pb-8 lg:pb-32  lg:w-1/2 lg:h-full">
                    <div className="flex flex-col justify-center items-center">
                        <h2 className="text-xl font-bold text-center mt-8 pb-8 sm:mt-20 max-w-[425px]">
                            {props.folderName
                                ? `"${props.folderName}"`
                                : 'Your folder'}
                            &nbsp;has been successfully shared with:
                        </h2>
                        <ol className="max-h-56 lg:h-56 mb-8 overflow-y-auto w-[320px] sm:max-w-[425px] invite-emails">
                            {props.invitedEmails.map((email, i) => (
                                <li
                                    className="flex pb-8 justify-center items-center w-full"
                                    key={i}
                                >
                                    <EnvelopeIcon
                                        aria-hidden="true"
                                        className="h-12 w-12 mr-2"
                                    />
                                    <p className="my-auto font-semibold text-xl truncate text-ellipsis w-[95%] sm:w-full">
                                        {email}
                                    </p>
                                </li>
                            ))}
                        </ol>
                    </div>

                    <footer className="mt-20 text-xl">
                        <h2 className="font-semibold text-center mb-6">
                            Did you forget anyone?
                        </h2>
                        <Button
                            buttonText="Add someone else"
                            buttonType={ButtonType.button}
                            disabled={false}
                            buttonTheme={ButtonTheme.primary}
                            className="w-full sm:max-w-max sm:px-20 text-xl mx-auto mb-8"
                            onClick={() => {
                                const sharedFolder = bookmarks?.find(
                                    (b) => b.localId === props.localFolderID
                                )
                                if (!sharedFolder) return
                                navigate(
                                    `/settings/${sharedFolder.remoteId}/invite`
                                )
                            }}
                        />
                    </footer>
                </section>
                <section className="flex flex-col justify-between bg-primary-light rounded lg:rounded-none lg:rounded-br lg:rounded-tr  pb-8 lg:pb-24 lg:w-1/2 px-4">
                    <h2 className="text-xl font-bold text-center mt-20 pb-8 lg:pb-0">
                        What happens next?
                    </h2>
                    <ol className=" w-full px-8 sm:px-16 md:px-32 lg:px-16 xl:px-32 2xl:px-40">
                        <li className="flex justify-between ml-4 mr-4 pb-8">
                            <div className="flex items-center justify-center">
                                <p className="text-6xl lg:text-5xl font-semibold text-primary select-none pr-4">
                                    1
                                </p>
                                <p className="ml-2 text-xl font-semibold leading-6">
                                    An email is sent to everyone you invited
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-between ml-4 mr-4 pb-8">
                            <div className="flex items-center justify-center">
                                <p className="text-6xl lg:text-5xl font-semibold text-primary select-none pr-4">
                                    2
                                </p>
                                <p className="ml-2 text-xl  font-semibold leading-6">
                                    They accept your invite and install Bookmark
                                    Llama
                                </p>
                            </div>
                        </li>
                        <li className="flex justify-between ml-4 mr-4 pb-8">
                            <div className="flex items-center justify-center">
                                <p className="text-6xl lg:text-5xl font-semibold text-primary select-none pr-4">
                                    3
                                </p>
                                <p className="ml-2 text-xl font-semibold leading-6">
                                    The bookmarks you shared will appear on
                                    their bookmark bar
                                </p>
                            </div>
                        </li>
                    </ol>
                    <Button
                        buttonText="Share another folder"
                        buttonType={ButtonType.button}
                        disabled={false}
                        buttonTheme={ButtonTheme.tertiary}
                        className="w-full sm:max-w-max sm:px-4 text-xl lg:mb-[70px] mx-auto font-semibold hover:bg-indigo-100"
                        onClick={() => {
                            //This is a hacky way to restart the wizard,
                            //we must do this because the wizard doesn't have any url support
                            navigate(0)
                        }}
                    />
                </section>
            </div>
        </div>
    )
}
