import apiHelper from '../apiClient/defaultApiClient'
import posthog from 'posthog-js'

export let posthogURL = 'https://dev.app.bookmarkllama.com/llama-locator'
if (import.meta.env.MODE === 'production') {
    posthogURL = 'https://app.bookmarkllama.com/llama-locator'
} else if (import.meta.env.MODE === 'staging') {
    posthogURL = 'https://staging.app.bookmarkllama.com/llama-locator'
} else if (import.meta.env.MODE === 'localenv') {
    posthogURL = ''
}

export let posthogToken: string
switch (import.meta.env.MODE) {
    case 'production':
        posthogToken = 'phc_CqrKnMEvqqBTlvGCnqaJr8rwtHNcJ697joLpt9LGeIr'
        break
    case 'staging':
        posthogToken = 'phc_v4znm79XkVowwUn4ANWiF3rdrsNAy2XUFt7zygK6gNM'
        break
    case 'localenv':
        posthogToken = ''
        break
    case 'e2e':
        posthogToken = ''
        break
    default:
        posthogToken = 'phc_3ptnqIVOeyfB7aUyAMCwKqFvavWwAd3bHNfBXDqQaXA'
}

export const posthogEnabled =
    posthogURL !== '' &&
    posthogToken !== '' &&
    window.localStorage.dont_track !== 'true'
