import { useState } from 'react'
import { SubmitHandler, useForm, useFormState } from 'react-hook-form'
import Section from '../helpers/commonComponents/Section'
import { ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import apiHelper from '../apiClient/defaultApiClient'
import { useCurrentUser } from './Profile'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import InputPassword from '../helpers/customComponents/InputPassword'
import { renderErrorForUpdatePassword } from '../helpers/helperFunctions'

interface UpdatePasswordInputs {
    newPassword: string
}
export default function UpdatePassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<UpdatePasswordInputs>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { user } = useCurrentUser()

    const putUpdatePassword = async (data: UpdatePasswordInputs) => {
        const userId = user ? user?.id : null
        if (!userId) return
        setIsLoading(true)
        try {
            await apiHelper
                .putUserById({
                    userID: userId,
                    body: { newPassword: data.newPassword },
                })
                .then(() => {
                    setValue('newPassword', '')
                    setIsLoading(false)
                    toastSuccess(uiText.Notifications.success.updatePassword)
                })
        } catch (e: any) {
            setIsLoading(false)
            toastError(uiText.Notifications.error.updatePassword)
        }
    }

    const onSubmit: SubmitHandler<UpdatePasswordInputs> = (data) => {
        putUpdatePassword(data)
    }

    return (
        <Section className="box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
            <>
                <h2 className="mb-4 text-center text-lg font-bold md:text-left">
                    Update your password
                </h2>
                <form
                    className="flex w-full flex-col items-end"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="relative flex w-full flex-col">
                        <InputPassword
                            labelText="New password*"
                            register={{
                                ...register('newPassword', {
                                    required: true,
                                    maxLength: 72,
                                }),
                            }}
                            errors={errors.newPassword}
                            isLoading={isLoading}
                            inputType="update-password"
                            showPasswordIconClass="bottom-[49%] right-[3%]"
                            customElement={renderErrorForUpdatePassword(
                                errors.newPassword
                            )}
                            containerClass="w-full"
                        />
                    </div>
                    <Button
                        buttonText={'Update Password'}
                        disabled={isLoading}
                        buttonType={ButtonType.submit}
                        className="mt-20 w-full sm:px-8 md:max-w-max"
                        buttonTheme={ButtonTheme.primary}
                    />
                </form>
            </>
        </Section>
    )
}
