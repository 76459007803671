/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSharedFolderDefaultPermissionRequest
 */
export interface PostSharedFolderDefaultPermissionRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderDefaultPermissionRequest
     */
    defaultPermission: PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum;
}


/**
 * @export
 */
export const PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum = {
    Read: 'read',
    Write: 'write'
} as const;
export type PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum = typeof PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum[keyof typeof PostSharedFolderDefaultPermissionRequestDefaultPermissionEnum];


/**
 * Check if a given object implements the PostSharedFolderDefaultPermissionRequest interface.
 */
export function instanceOfPostSharedFolderDefaultPermissionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "defaultPermission" in value;

    return isInstance;
}

export function PostSharedFolderDefaultPermissionRequestFromJSON(json: any): PostSharedFolderDefaultPermissionRequest {
    return PostSharedFolderDefaultPermissionRequestFromJSONTyped(json, false);
}

export function PostSharedFolderDefaultPermissionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderDefaultPermissionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultPermission': json['default_permission'],
    };
}

export function PostSharedFolderDefaultPermissionRequestToJSON(value?: PostSharedFolderDefaultPermissionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_permission': value.defaultPermission,
    };
}

