import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import RadioButton, { RadioButtonOption } from '../../share/Wizard/RadioButton'
import { Tooltip } from 'react-tooltip'
import { WizardStepMultiChoiceOption, WizardStepType } from './types'
import Button, { ButtonType } from '../../helpers/customComponents/Button'
import { ButtonTheme } from '../../helpers/constants/enum'

export function WizardStep(props: {
    wizardStep: WizardStepType
    isFirstStep: boolean
    onNext: () => void
    onPrevious: () => void
    onChange: (value: any) => void
    currentValue: any
    hasError: boolean
    userCreated?: Date
}) {
    if (props.wizardStep.type === 'end') {
        return <>{props.wizardStep.finishPage}</>
    }

    let nextButton = (
        <button
            onClick={props.onNext}
            type="button"
            className="flex items-center text-primary font-bold bg-primary-light py-2 px-3 hover:bg-transparent rounded"
        >
            Next
            <ArrowRightIcon className="h-6 w-6 flex ml-4 stroke-2" />
        </button>
    )

    if (
        props.wizardStep.type === 'custom' &&
        props.wizardStep.customNextButton
    ) {
        const step = props.wizardStep

        nextButton = props.wizardStep.customNextButton(
            props.onNext,
            step.validate
        )
    }

    return (
        <>
            <h2 className="text-2xl font-bold text-center mt-16 sm:mt-20">
                {props.wizardStep.title}
            </h2>
            <div className="flex flex-col justify-center lg:px-32 xl:px-64 2xl:px-96 items-center text-lg w-full">
                <div className="flex flex-col lg:flex-row items-center lg:items-end justify-center w-full">
                    {props.wizardStep.type === 'multi-choice' && (
                        <RadioButton
                            options={MultiChoiceOptionsToRadioButtonOptions(
                                props.wizardStep.options
                            )}
                            onChange={(value: string) => {
                                props.onChange(value)
                            }}
                            label="Would you like to share or sync your bookmarks?"
                            value={props.currentValue}
                            isLoading={false}
                        />
                    )}
                </div>
                {props.wizardStep.type === 'custom' &&
                    props.wizardStep.component}
                {props.hasError ? (
                    <span className="self-start md:ml-4 text-red-800 font-semibold text-lg">
                        {(props.wizardStep as any).errorMessage
                            ? (props.wizardStep as any).errorMessage
                            : 'Please select an option to continue'}
                    </span>
                ) : (
                    <span className="h-[28px]"></span>
                )}
                <div
                    className={`flex ${
                        props.isFirstStep ? 'justify-end' : 'justify-between'
                    } w-full my-10 sm:my-12 sm:flex-row items-center`}
                >
                    {!props.isFirstStep && (
                        <button
                            onClick={props.onPrevious}
                            type="button"
                            className="flex items-center font-semibold"
                        >
                            <ArrowLeftIcon className="h-6 w-6 flex mr-4 stroke-2" />
                            Previous
                        </button>
                    )}
                    {nextButton}
                </div>
            </div>
        </>
    )
}

const MultiChoiceOptionsToRadioButtonOptions = (
    option: WizardStepMultiChoiceOption[]
): RadioButtonOption[] => {
    return option.map((option) => {
        return {
            title: option.title,
            value: option.id,
            icon: option.icon,
            toolTip: !option.tooltip
                ? undefined
                : {
                      id: option.id,
                      content: option.tooltip,
                      element: <Tooltip id={option.id} />,
                  },
        }
    })
}
