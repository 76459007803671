import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom'
import Controller from './dashboard/Controller'
import Dashboard from './dashboard/Dashboard'
import Profile from './profile/Profile'
import Settings from './settings/Settings'
import ViewMembers from './settings/ViewMembers'
import Invite from './settings/Invite'
import StopSharing from './settings/StopSharing'
import Permissions from './settings/Permissions'
import ChangeOwner from './settings/ChangeOwner'
import DeleteAccount from './profile/DeleteAccount'
import Account from './profile/Account'
import Billing from './profile/Billing'
import Support from './profile/Support'
import UpdatePassword from './profile/UpdatePassword'
import { Debug } from './debug/debug'
import ManageOrganisation from './organisation/ManageOrganisation'
import ErrorPage from './helpers/commonComponents/ErrorPage'
import NotFound from './helpers/commonComponents/NotFound'
import UninstallSurvey from './uninstall-survey/UninstallSurvey'
import SurveySubmitted from './uninstall-survey/SurveySubmitted'
import ResetPassword from './helpers/commonComponents/ResetPassword'
import NewRegistration from './registration/NewRegistration'
import NewLogin from './registration/NewLogin'
import { ShareFolderWizard } from './pages/ShareFolder'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorPage />}>
            <Route path="/register" element={<NewRegistration />} />
            <Route path="/login" element={<NewLogin />} />
            <Route path="/uninstall/*" element={<UninstallSurvey />} />
            <Route path="/submitted" element={<SurveySubmitted />} />
            <Route path="/reset-password/*" element={<ResetPassword />} />

            <Route path="/" element={<Controller />}>
                <Route index element={<Dashboard />} id="dashboard" />

                <Route
                    path="settings/:folderId"
                    element={<Settings />}
                    id="settings"
                >
                    <Route index element={<ViewMembers />} />
                    <Route
                        path="members"
                        element={<ViewMembers />}
                        id="members"
                    />
                    <Route path="invite" element={<Invite />} id="invite" />
                    <Route
                        path="stop-sharing"
                        element={<StopSharing />}
                        id="stop-sharing"
                    />
                    <Route
                        path="permissions"
                        element={<Permissions />}
                        id="permissions"
                    />
                    <Route
                        path="change-owner"
                        element={<ChangeOwner />}
                        id="change-owner"
                    />
                </Route>

                <Route path="profile" element={<Profile />} id="profile">
                    <Route index element={<Account />} />
                    <Route path="account" element={<Account />} id="account" />
                    <Route
                        path="delete"
                        element={<DeleteAccount />}
                        id="delete"
                    />
                    <Route path="billing" element={<Billing />} id="billing" />
                    <Route path="support" element={<Support />} id="support" />
                    <Route
                        path="update"
                        element={<UpdatePassword />}
                        id="password"
                    />
                </Route>
                <Route
                    path="share"
                    element={<ShareFolderWizard />}
                    id="share"
                />
                <Route
                    path="organisation/:organisationId"
                    element={<ManageOrganisation />}
                    id="organisation"
                />
                <Route path="*" element={<NotFound />} />
                <Route path="error" element={<ErrorPage />} />
            </Route>

            <Route path="/debug" element={<Debug />} />
        </Route>
    )
)
